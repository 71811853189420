import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../common/GetProxy";
import { handleResponseCode } from "../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();


export const getApplicationList = createAsyncThunk(
  "get/getApplicationList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");

    const { userRole } =props;
    console.log("userRoleuserRoleuserRoleuserRoleuserRole", userRole);
    try {
      const response = await fetch(
        `${proxy}/api/v1//cmdb/${User}/${userRole[0]}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
        }
      )
      handleResponseCode(response);
      const data = await response.json(); 
          
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const MarketplaceSlice = createSlice({
  name: "MarketplaceSlice",
  initialState: {
    applicationListData : [],
    applicationDataLoading : false,
  },
  reducers: {
  },
    extraReducers: (builder) => {
      builder
        //getApplicationList
        .addCase(getApplicationList.pending, (state, action) => {
          state.applicationDataLoading = true;
          state.error = false;
        })
        .addCase(getApplicationList.fulfilled, (state, action) => {
          state.applicationDataLoading = false;
          state.applicationListData = action.payload;
          state.error = false;
        })
        .addCase(getApplicationList.rejected, (state, action) => {
          state.applicationDataLoading = false;
          state.applicationListData = [];
          state.error = true;
        })
    }
});

export const {} = MarketplaceSlice.actions;

export default MarketplaceSlice.reducer;
