import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import GetProxy from "../../../../common/GetProxy";
import { handleResponseCode } from '../../../../../app/utilities/helpers';
const proxy = GetProxy();
const cookies = new Cookies();

export const dataImportList = createAsyncThunk(
  "post/dataImportList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`${proxy}/api/v1/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)


export const dataImportListCount = createAsyncThunk(
  "post/dataImportListCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(`${proxy}/api/v1/listCountWithSearch`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      throw error;
    }
  }
)

export const dataImportSave = createAsyncThunk("post/dataImportSave", async (props) => {
  try {
    const { savePayload, userName } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/triggerSave/${userName}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(savePayload),
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const dataImportGetById = createAsyncThunk("get/dataImportGetById", async (props) => {
  const id = props;
  const Apitoken = cookies.get("apitoken");
  const User = cookies.get("username");
  try {
  const response = await fetch(`${proxy}/api/v1/getById/${id}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "CMTS-API-TOKEN": Apitoken,
      USERNAME: User,
    },
  })
  handleResponseCode(response);
  const data = await response.json();
  return response.ok ? data : Promise.reject(data);
} catch(error) {
  console.error("Error:", error);
  throw error;
}
});

export const changeDataStatus = createAsyncThunk("put/dataImportGetById", async (props) => {
  const {id,status,userName} = props;
  const Apitoken = cookies.get("apitoken");
  const User = cookies.get("username");
  try {
  const response = await fetch(`${proxy}/api/v1/dataImportStatusUpdate/${id}/${status}/${userName}`, {
    method: "PUT",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "CMTS-API-TOKEN": Apitoken,
      USERNAME: User,
    },
  })
  handleResponseCode(response);
  const data = await response.json();
  return response.ok ? data : Promise.reject(data);
} catch(error) {
  console.error("Error:", error);
  throw error;
}
});

export const dataImportDelete = createAsyncThunk("DELETE/dataImportDelete", async (props) => {
  try {
    const { id, userName } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const response = await fetch(
      `${proxy}/api/v1/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "CMTS-API-TOKEN": Apitoken,
          USERNAME: User,
        },
      }
    );
    handleResponseCode(response);
    const data = await response.json();
    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});



const initialState = {
  tableDILoading: false,
  listTableDIData: [],
  tableCountDILoading: false,
  listTableDataDICount: [],
  tableDIDeleteReload: false,
  tableDISaveReload: false,
  tableStatusReload : false,
  saveDILoader:false,
  getDILoader:false,
  deleteDILoader:false,
  error : false,
};
export const DataImportConfigSlice = createSlice({
  name: 'DataImportConfigSlice',
  initialState: initialState,
  reducers: {
    setErrorAlert: {
      reducer(state, action) {
        state.error = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(dataImportList.pending, (state, action) => {
        state.tableDILoading = true;
        state.error = false;
      })
      .addCase(dataImportList.fulfilled, (state, action) => {
        state.listTableDIData = action.payload;
        state.tableDILoading = false;
        state.tableDIDeleteReload = false;
        state.tableDISaveReload = false;
        state.tableStatusReload = false;
        state.error = false;
      })
      .addCase(dataImportList.rejected, (state, action) => {
        state.tableDILoading = false;
        state.error = true;
      })


      .addCase(dataImportListCount.pending, (state, action) => {
        state.tableCountDILoading = true;
        state.error = false;
      })
      .addCase(dataImportListCount.fulfilled, (state, action) => {
        state.listTableDataDICount = action.payload;
        state.tableCountDILoading = false;
        state.error = false;
      })
      .addCase(dataImportListCount.rejected, (state, action) => {
        state.tableCountDILoading = false;
        state.error = true;
      })
      .addCase(dataImportDelete.pending, (state, action) => {
        state.deleteDILoader = true;
        state.error = false;
      })
      .addCase(dataImportDelete.fulfilled, (state, action) => {
        state.tableDIDeleteReload = true;
        state.tableDISaveReload = false;
        state.tableStatusReload = false;
        state.deleteDILoader = false;
        state.error = false;
      })
      .addCase(dataImportDelete.rejected, (state, action) => {
        state.tableDIDeleteReload = false;
        state.tableDISaveReload = false;
        state.tableStatusReload = false;
        state.deleteDILoader = false;
        state.error = true;
      })
      .addCase(dataImportSave.pending, (state, action) => {
        // state.tableDIDeleteReload = false;
        //state.tableDISaveReload = true;
        state.saveDILoader = true;
        state.error = false;
      })
      .addCase(dataImportSave.fulfilled, (state, action) => {
        state.tableDIDeleteReload = false;
        // state.tableDISaveReload = true;
        state.tableStatusReload = false;
        state.saveDILoader = false;
        state.error = false;
      })
      .addCase(dataImportSave.rejected, (state, action) => {
        // state.tableDIDeleteReload = false;
        // state.tableDISaveReload = true;
        state.saveDILoader = false;
        state.error = true;
      })
      .addCase(changeDataStatus.fulfilled, (state, action) => {
        state.tableDIDeleteReload = false;
        state.tableDISaveReload = false;
        state.tableStatusReload = true;
        state.error = false;
      })
      .addCase(changeDataStatus.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(dataImportGetById.pending, (state, action) => {
        state.getDILoader = true;
        state.error = false;
      })
      .addCase(dataImportGetById.fulfilled, (state, action) => {
        state.getDILoader = false;
        state.error = false;
      })
      .addCase(dataImportGetById.rejected, (state, action) => {
        state.getDILoader = false;
        state.error =  true;
      })

  },

});
export const { setErrorAlert } = DataImportConfigSlice.actions;
export default DataImportConfigSlice.reducer;