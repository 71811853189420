import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../../common/GetProxy";
import { handleResponseCode } from "../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();


export const userSessionListAPI = createAsyncThunk(
  "post/userSessionListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageLimit, pageNo } = props;
      const response = await fetch(
        `${proxy}/api/v1/auditLog/getAuthLog/${pageNo}/${pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(response);
      const data = await response.json(); 
          
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const UserSessionSlice = createSlice({
  name: "UserSessionSlice",
  initialState: {
    userSessionListData : [],
    userSessionListDataLoading : false,
  },
  reducers: {
  },
    extraReducers: (builder) => {
      builder
        //userSessionListAPI
        .addCase(userSessionListAPI.pending, (state, action) => {
          state.userSessionListDataLoading = true;
          state.error = false;
        })
        .addCase(userSessionListAPI.fulfilled, (state, action) => {
          state.userSessionListDataLoading = false;
          state.userSessionListData = action.payload;
          state.error = false;
        })
        .addCase(userSessionListAPI.rejected, (state, action) => {
          state.userSessionListDataLoading = false;
          state.userSessionListData = [];
          state.error = true;
        })
    }
});

export const {} = UserSessionSlice.actions;

export default UserSessionSlice.reducer;
