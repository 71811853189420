import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../common/GetProxy";
import { handleResponseCode } from "../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const getCRPendingListAPI = createAsyncThunk(
    "post/getCRPendingListAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarManualIntervention/changeRequestPendingDetailsList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getMatchingCrsDetailsListAPI = createAsyncThunk(
    "post/getMatchingCrsDetailsListAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { complianceId, pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarManualIntervention/getMatchingCrsDetailsList/${complianceId}/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getCRMappingSaveAPI = createAsyncThunk(
    "post/getCRMappingSaveAPI",
    async (props) => {
        try {
            const { savePayload } = props;
            const Apitoken = cookies.get("apitoken");
            const User = cookies.get("username");
            const response = await fetch(`${proxy}/api/v1/sirarManualIntervention/changeRequestMapping`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": Apitoken,
                    USERNAME: User,
                },
                body: JSON.stringify(savePayload)
            });
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    });
    
export const getAllCompletedCRsAPI = createAsyncThunk(
    "post/getAllCompletedCRsAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarManualIntervention/getAllCompletedChangeRequests/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

const initialState = {
    getCRPendingListLoading: false,
    getCRPendingListData: [],
    getMatchingCrsDetailsListLoading: false,
    getMatchingCrsDetailsListData: [],
    getCRMappingSaveLoading: false,
    getCRMappingSaveData: [],
    getAllCompletedCRsLanding: false,
    getAllCompletedCRsData: [],
    apierror: false,
};
export const CRMappingSlice = createSlice({
    name: "CRMappingSlice",
    initialState,

    reducers: {
        setInitialDataSlice: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder

            //CRPendingTableAPI
            .addCase(getCRPendingListAPI.pending, (state, action) => {
                state.getCRPendingListLoading = true;
                state.apierror = false;
            })
            .addCase(getCRPendingListAPI.fulfilled, (state, action) => {
                state.getCRPendingListLoading = false;
                state.getCRPendingListData = action.payload;
                state.apierror = false;
            })
            .addCase(getCRPendingListAPI.rejected, (state, action) => {
                state.getCRPendingListLoading = false;
                state.getCRPendingListData = [];
                state.apierror = true;
            })

            //getMatchingCrsDetailsListAPI
            .addCase(getMatchingCrsDetailsListAPI.pending, (state, action) => {
                state.getMatchingCrsDetailsListLoading = true;
                state.apierror = false;
            })
            .addCase(getMatchingCrsDetailsListAPI.fulfilled, (state, action) => {
                state.getMatchingCrsDetailsListLoading = false;
                state.getMatchingCrsDetailsListData = action.payload;
                state.apierror = false;
            })
            .addCase(getMatchingCrsDetailsListAPI.rejected, (state, action) => {
                state.getMatchingCrsDetailsListLoading = false;
                state.getMatchingCrsDetailsListData = [];
                state.apierror = true;
            })

            //getCRMappingSaveAPI
            .addCase(getCRMappingSaveAPI.pending, (state, action) => {
                state.getCRMappingSaveLoading = true;
                state.apierror = false;
            })
            .addCase(getCRMappingSaveAPI.fulfilled, (state, action) => {
                state.getCRMappingSaveLoading = false;
                state.getCRMappingSaveData = action.payload;
                state.apierror = false;
            })
            .addCase(getCRMappingSaveAPI.rejected, (state, action) => {
                state.getCRMappingSaveLoading = false;
                state.getCRMappingSaveData = [];
                state.apierror = true;
            })

            //getCRMappingSaveAPI
            .addCase(getAllCompletedCRsAPI.pending, (state, action) => {
                state.getAllCompletedCRsLanding = true;
                state.apierror = false;
            })
            .addCase(getAllCompletedCRsAPI.fulfilled, (state, action) => {
                state.getAllCompletedCRsLanding = false;
                state.getAllCompletedCRsData = action.payload;
                state.apierror = false;
            })
            .addCase(getAllCompletedCRsAPI.rejected, (state, action) => {
                state.getAllCompletedCRsLanding = false;
                state.getAllCompletedCRsData = [];
                state.apierror = true;
            })
    },
});

export default CRMappingSlice.reducer;