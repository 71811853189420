/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "../../../../app/commonSlice";
import Cookies from "universal-cookie";
import GetProxy from "../../../common/GetProxy";
import { handleResponseCode } from "../../../../app/utilities/helpers";
const proxy = GetProxy();
const cookies = new Cookies();

export const saveAORBudget = createAsyncThunk(
  "post/saveCB",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { cmdbId, payload} = props;

      const response = await fetch(
        `${proxy}/api/v1/cloudAorController/saveOrUpdateAorBudget/${cmdbId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();
      // dispatch(
      //   getCountAOR({payload:listPayload.payload,cmdbId:listPayload.cmdbId})
      // );
      // dispatch(getListAOR(listPayload));

      return response.ok ? res : Promise.reject(res);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCountAOR = createAsyncThunk(
  "post/getCountAOR",
  async (props) => {
      const { payload,cmdbId } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
          const response = await fetch(
              `${proxy}/api/v1/cloudAorController/getAorBudgetListCount/${cmdbId}`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "CMTS-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();

          return response.ok ? data : Promise.reject(data);
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);
export const getListAOR = createAsyncThunk(
  "post/getListAOR",
  async (props) => {
      const { pageNumber, itemsPerPage,cmdbId, payload } = props;
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
          const response = await fetch(
              `${proxy}/api/v1/cloudAorController/getAorBudgetListWithSearchAndPagination/${cmdbId}/${pageNumber}/${itemsPerPage}`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "CMTS-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data)
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);
export const getByIdAOR = createAsyncThunk("get/getByIdAOR", async (props) => {
  const id = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
  const response = await fetch(`${proxy}/api/v1/cloudAorController/getAorBudgetById/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "CMTS-API-TOKEN": apiToken,
      USERNAME: username,
    },
  });
  handleResponseCode(response);
  const data = await response.json();
  return response.ok ? data : Promise.reject(data)
} catch (error) {
  console.error("Error:", error);
  throw error;
}
}
);
export const deleteAOR = createAsyncThunk(
  "post/deleteAOR",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id, cmdbId} = props;

      const response = await fetch(
        `${proxy}/api/v1/cloudAorController/deleteAorBudget/${id}`,
        {
          method: "Delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "CMTS-API-TOKEN": apiToken,
            USERNAME: username,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      handleResponseCode(response);
      const res = await response.json();

      return response.ok ? res : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error so the calling code can handle it
    }
  }
);
const initialState={
  saveDataAOR: "",
  saveLoadingAOR: false,
  tableListAOR: [],
  tableLoadingAOR: false,
  getDataAOR: [],
  getLoadingAOR: false,
  deleteAOR:"",
  deleteLoadingAOR:false,
  CountAOR:'',
  CountLoadingAOR:false,
  error : false,
}
export const AORBudgetSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataCB: (state) => {
      const resetDatas={
        saveDataAOR: "",
        saveLoadingAOR: false,
        tableListAOR: [],
        tableLoadingAOR: false,
        getDataAOR: [],
        getLoadingAOR: false,
        deleteAOR:"",
        deleteLoadingAOR:false,
        CountAOR:'',
        CountLoadingAOR:false,
        AORReload: false,
        error : false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  reducers: {
    setAlertError: (state, action) => {
      state.error = false;
    },
    setAORreload: (state, action) => {
      state.AORReload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAORBudget.pending, (state, action) => {
        state.saveLoadingAOR = true;
        state.saveDataAOR = "";
        state.error = false;
      })
      .addCase(saveAORBudget.fulfilled, (state, action) => {
        state.saveLoadingAOR = false;
        state.saveDataAOR = action.payload;
        state.error = false;
      })
      .addCase(saveAORBudget.rejected, (state, action) => {
        state.saveLoadingAOR = false;
        state.error = true;
      })

      
      .addCase(getListAOR.pending, (state, action) => {
        state.tableLoadingAOR = true;
        state.error = false;
      })
      .addCase(getListAOR.fulfilled, (state, action) => {
        state.tableLoadingAOR = false;
        state.tableListAOR = action.payload;
        state.error = false;
      })
      .addCase(getListAOR.rejected, (state, action) => {
        state.tableLoadingAOR = false;
        state.error = true;
      })
      .addCase(getByIdAOR.pending, (state, action) => {
        state.getLoadingAOR = true;
        state.error = false;
      })
      .addCase(getByIdAOR.fulfilled, (state, action) => {
        state.getLoadingAOR = false;
        state.getDataAOR = action.payload;
        state.error = false;
      })
      .addCase(getByIdAOR.rejected, (state, action) => {
        state.getLoadingAOR = false;
        state.error = true;
      })
      .addCase(deleteAOR.pending, (state, action) => {
        state.deleteLoadingAOR = true;
        state.deleteAOR = "";
        state.error = false;
      })
      .addCase(deleteAOR.fulfilled, (state, action) => {
        state.deleteLoadingAOR = false;
        state.deleteAOR = action.payload;
        state.error = false;
      })
      .addCase(deleteAOR.rejected, (state, action) => {
        state.deleteLoadingAOR = false;
        state.error = true;
      })
      .addCase(getCountAOR.pending, (state, action) => {
        state.CountLoadingAOR = true;
        state.error = false;
      })
      .addCase(getCountAOR.fulfilled, (state, action) => {
        state.CountLoadingAOR = false;
        state.CountAOR = action.payload;
        state.error = false;
      })
      .addCase(getCountAOR.rejected, (state, action) => {
        state.CountLoadingAOR = false;
        state.error = true;
      })
  },
});
export const { resetDataCB, setAlertError, setAORreload } = AORBudgetSlice.actions;
export default AORBudgetSlice.reducer;
