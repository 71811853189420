import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import GetProxy from "../common/GetProxy";
import { handleResponseCode } from "../../app/utilities/helpers";
import moment from "moment";

const proxy = GetProxy();
const cookies = new Cookies();

export const getAllCategory = createAsyncThunk(
    "post/getAllDeptDivsionAndApp",
    async () => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(
                `${proxy}/api/v1/sirarCompliantController/getSirarFilter`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    // body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const getServerNodeData = createAsyncThunk(
    "post/getServerNodeData",
    async (props) => {
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const {id,URL} = props;
      try {
        const response = await fetch(
          `${proxy}/api/v1/${URL}/${id}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": Apitoken,
              USERNAME: User,
            },
          }
        );
        handleResponseCode(response);
        const data = await response.json();
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
)

export const CompliantTableList = createAsyncThunk(
    "post/CompliantTableList",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getCompliantFindingsList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const CompliantTableListCount = createAsyncThunk(
    "post/CompliantTableListCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getCompliantFindingsTableListCount`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getNonCompFindingsSirarListAPI = createAsyncThunk(
    "post/getNonCompFindingsSirarListAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const {  pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getNonCompliantFindingsList/${pageNumber}/${itemsPerPage}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});

export const getNonCompFindingsSirarCountAPI = createAsyncThunk(
    "post/getNonCompFindingsSirarCountAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const {  pageNumber, itemsPerPage, payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getNonCompliantFindingsCount`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log('Error', error);
            throw (error)
        }
});


export const getReasonDetailsPopUp = createAsyncThunk(
    "post/getReasonDetailsPopUp",
    async (props) => {
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const {changeRequestId,URL} = props;
      try {
        const response = await fetch(
          `${proxy}/api/v1/sirarNonCompliantController/getReasonDetailsPopUp/${changeRequestId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": Apitoken,
              USERNAME: User,
            },
          }
        );
        handleResponseCode(response);
        const data = await response.json();
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
)

export const getInfoDetailsPopUp = createAsyncThunk(
    "get/getInfoDetailsPopUp",
    async (props) => {
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const {id,URL} = props;
      try {
        const response = await fetch(
          `${proxy}/api/v1/sirarNonCompliantController/getInfoPopUp/${id}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "CMTS-API-TOKEN": Apitoken,
              USERNAME: User,
            },
          }
        );
        handleResponseCode(response);
        const data = await response.json();
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
)


    export const NonCompliantAckTableList = createAsyncThunk(
        "post/NonCompliantAckTableList",
        async (props) => {
            const apiToken = cookies.get("apitoken");
            const username = cookies.get("username");
            try {
                const { pageNumber, itemsPerPage, payload } = props;

                const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getNonCompliantFindingsAcknowledgedList/${pageNumber}/${itemsPerPage}`, {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME: username,

                    },
                    body: JSON.stringify(payload)
                })
                handleResponseCode(response);
                const data = await response.json();
                return response.ok ? data : Promise.reject(data);
            } catch (error) {
                console.log("Error", error);
                throw (error)
            }
        });

    export const NonCompliantAckTableListCount = createAsyncThunk(
        "post/NonCompliantAckTableListCount",
        async (props) => {
            const apiToken = cookies.get("apitoken");
            const username = cookies.get("username");
            try {
                const { payload } = props;

                const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/getAcknowledgedNonCompliantFindingsCount`, {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "CMTS-API-TOKEN": apiToken,
                        USERNAME: username,

                    },
                    body: JSON.stringify(payload)
                })
                handleResponseCode(response);
                const data = await response.json();
                return response.ok ? data : Promise.reject(data);
            } catch (error) {
                console.log("Error", error);
                throw (error)
            }
        });

export const ManageCompliance = createAsyncThunk(
    "post/ManageCompliance",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { formData } = props;
console.log("formData", formData)
            const response = await fetch(`${proxy}/api/v1/sirarNonCompliantController/manageCompliance`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    // "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: formData
            })
            // handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });
    
export const getNonComplianceTeamAPI = createAsyncThunk(
    "post/getNonComplianceTeamAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getNonComplianceTeam
`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });
    
export const getNonComplianceActionPlanAPI = createAsyncThunk(
    "post/getNonComplianceActionPlanAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getNonCompliantFindingsByActionPlan
`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getComplianceStatusCountAPI = createAsyncThunk(
    "post/getComplianceStatusCountAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getComplianceStatusCount

`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getNonCompFindingsDistributionAPI = createAsyncThunk(
    "post/getNonCompFindingsDistributionAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getNonCompliantFindingsDistribution

`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getNonCompACKFindingsDistributionAPI = createAsyncThunk(
    "post/getNonCompACKFindingsDistributionAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getNonCompliantAcknowledgedFindingsDistribution

`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getFindingsTrendSirarAPI = createAsyncThunk(
    "post/getFindingsTrendSirarAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getFindingsTrendChart

`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getNonCompliantByCRCategoriesAPI = createAsyncThunk(
    "post/getNonCompliantByCRCategoriesAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getNonCompliantByCRCategory

`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

export const getNonCompliantByApplicationAPI = createAsyncThunk(
    "post/getNonCompliantByApplicationAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;

            const response = await fetch(`${proxy}/api/v1/sirarCompliantController/getNonCompliantByApplication

`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "CMTS-API-TOKEN": apiToken,
                    USERNAME: username,

                },
                body: JSON.stringify(payload)
            })
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.log("Error", error);
            throw (error)
        }
    });

    export const getAllTeams = createAsyncThunk(
        "get/getAllTeams",
        async () => {
            const apiToken = cookies.get("apitoken");
            const username = cookies.get("username");
            try {
                const response = await fetch(
                    `${proxy}/api/v1/sirarNonCompliantController/listOfTeams`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "CMTS-API-TOKEN": apiToken,
                            USERNAME: username,
                        },
                        // body: JSON.stringify(payload),
                    }
                );
                handleResponseCode(response);
                const data = await response.json();
    
                return response.ok ? data : Promise.reject(data);
            } catch (error) {
                console.error("Error:", error);
                throw error;
            }
        }
    );

    export const getRisk = createAsyncThunk(
        "get/getRisk",
        async () => {
            const apiToken = cookies.get("apitoken");
            const username = cookies.get("username");
            try {
                const response = await fetch(
                    `${proxy}/api/v1/sirarNonCompliantController/listOfRiskTypes`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "CMTS-API-TOKEN": apiToken,
                            USERNAME: username,
                        },
                        // body: JSON.stringify(payload),
                    }
                );
                handleResponseCode(response);
                const data = await response.json();
    
                return response.ok ? data : Promise.reject(data);
            } catch (error) {
                console.error("Error:", error);
                throw error;
            }
        }
    );
const initialState = {
    sirarFilterData: [],
    sirarFilterLoading: false,
    getNonCompFindingsSirarListData: [],
    getNonCompFindingsSirarListLoading: false,
    getNonCompFindingsSirarCountData: [],
    getNonCompFindingsSirarCountLoading: false,
    selectedFiltersData : {
        selectedDate : {
            timePicker: false,
            maxDate: moment().toDate(),
            autoApply: true,
            startDate: moment().startOf("month").format('YYYY-MM-DDT00:00:00.000'),
            endDate: moment().add(1, 'day').startOf('day').format('YYYY-MM-DDT00:00:00.000'),
            locale: {
                format: 'DD/MM/YYYY',
            },
        },
        selectedDivision : [],
        selectedApplication : [],
        selectedApplicationName : [],
        selectedTeams : [],
        selectedCategory : [],
    },
    activeTabKey : "NonCompliantFindings",
    tablereload : false,
    complaintTableData : [],
    CompliantTableListLoading :  false,
    complaintTableCountData : [],
    CompliantTableCountDataLoading :  false,
    serverNodeData : [],
    serverNodeLoading :  false,
    getReasonDetailsPopUpData : [],
    getReasonDetailsPopUpLoading :  false,
    error: false,
    NonComplaintAckTableData : [],
    NonCompliantAckTableListLoading :  false,
    NonComplaintAckTableCountData : [],
    NonCompliantAckTableCountDataLoading :  false,
    InfoListData : [],
    InfoListDataLoading :  false,
    manageComplianceData : [],
    manageComplianceLoading :  false,
    getNonComplianceTeamData : [],
    getNonComplianceTeamLoading :  false,
    getNonComplianceActionPlanAPIData : [],
    getNonComplianceActionPlanLoading :  false,
    getComplianceStatusCountData : [],
    getComplianceStatusCountLoading :  false,
    getNonCompFindingsDistributionData : [],
    getNonCompFindingsDistributionLoading :  false,
    getNonCompACKFindingsDistributionData : [],
    getNonCompACKFindingsDistributionLoading :  false,
    getFindingsTrendSirarData : [],
    getFindingsTrendSirarLoading :  false,
    getNonCompliantByCRCategoriesData : [],
    getNonCompliantByCRCategoriesLoading :  false,
    getNonCompliantByApplicationData : [],
    getNonCompliantByApplicationLoading :  false,
    TeamsData : [],
    TeamsLoading :  false,
    RiskData : [],
    RiskLoading :  false,
};
export const SirarfilterSlice = createSlice({
    name: "SirarfilterSlice",
    initialState,

    reducers: {
        resetAllFilter: (state) => {
            state.selectedFiltersData = {
                ...initialState.selectedFiltersData,
            };
        },
        resetSirarSliceData: () => {
            return initialState;
        },
        setTableData: (state, action) => {
            state.getNonCompFindingsSirarListData = action.payload;
        },
        setError: (state, action) => {
            state.error = false;
        },
        setSelectedFiltersData: (state, action) => {
            state.selectedFiltersData = action.payload;
        },
        setActiveTabKey: (state, action) => {
            state.activeTabKey = action.payload;
        },
        setTableReload: (state, action) => {
            state.tablereload = action.payload;
        },
        
    },

    extraReducers: (builder) => {
        builder

            .addCase(getAllCategory.pending, (state, action) => {
                state.sirarFilterData = true;
                state.error = false;
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.sirarFilterData = action.payload;
                state.sirarFilterLoading = false;
                state.error = false;
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.sirarFilterLoading = false;
                state.sirarFilterData = [];
                state.error = true;
            })

            .addCase(CompliantTableList.pending, (state, action) => {
                state.CompliantTableListLoading = true;
                state.error = false;
            })
            .addCase(CompliantTableList.fulfilled, (state, action) => {
                state.complaintTableData = action.payload;
                state.CompliantTableListLoading = false;
                state.error = false;
            })
            .addCase(CompliantTableList.rejected, (state, action) => {
                state.CompliantTableListLoading = false;
                state.complaintTableData = [];
                state.error = true;
            })

            .addCase(CompliantTableListCount.pending, (state, action) => {
                state.CompliantTableCountDataLoading = true;
                state.error = false;
            })
            .addCase(CompliantTableListCount.fulfilled, (state, action) => {
                state.complaintTableCountData = action.payload;
                state.CompliantTableCountDataLoading = false;
                state.error = false;
            })
            .addCase(CompliantTableListCount.rejected, (state, action) => {
                state.CompliantTableCountDataLoading = false;
                state.complaintTableCountData = [];
                state.error = true;
            })

            .addCase(getServerNodeData.pending, (state, action) => {
                state.serverNodeLoading = true;
                state.error = false;
            })
            .addCase(getServerNodeData.fulfilled, (state, action) => {
                state.serverNodeData = action.payload;
                state.serverNodeLoading = false;
                state.error = false;
            })
            .addCase(getServerNodeData.rejected, (state, action) => {
                state.serverNodeLoading = false;
                state.serverNodeData = [];
                state.error = true;
            })

    //getNonCompFindingsSirarListAPI
            .addCase(getNonCompFindingsSirarListAPI.pending, (state, action) => {
                state.getNonCompFindingsSirarListLoading = true;
                state.error = false;
            })
            .addCase(getNonCompFindingsSirarListAPI.fulfilled, (state, action) => {
                state.getNonCompFindingsSirarListData = action.payload;
                state.getNonCompFindingsSirarListLoading = false;
                state.error = false;
            })
            .addCase(getNonCompFindingsSirarListAPI.rejected, (state, action) => {
                state.getNonCompFindingsSirarListLoading = false;
                state.getNonCompFindingsSirarListData = [];
                state.error = true;
            })

    //getNonCompFindingsSirarCountAPI
            .addCase(getNonCompFindingsSirarCountAPI.pending, (state, action) => {
                state.getNonCompFindingsSirarCountLoading = true;
                state.error = false;
            })
            .addCase(getNonCompFindingsSirarCountAPI.fulfilled, (state, action) => {
                state.getNonCompFindingsSirarCountData = action.payload;
                state.getNonCompFindingsSirarCountLoading = false;
                state.error = false;
            })
            .addCase(getNonCompFindingsSirarCountAPI.rejected, (state, action) => {
                state.getNonCompFindingsSirarCountLoading = false;
                state.getNonCompFindingsSirarCountData = [];
                state.error = true;
            })

    //getNonCompFindingsSirarCountAPI
            .addCase(getReasonDetailsPopUp.pending, (state, action) => {
                state.getReasonDetailsPopUpLoading = true;
                state.error = false;
            })
            .addCase(getReasonDetailsPopUp.fulfilled, (state, action) => {
                state.getReasonDetailsPopUpData = action.payload;
                state.getReasonDetailsPopUpLoading = false;
                state.error = false;
            })
            .addCase(getReasonDetailsPopUp.rejected, (state, action) => {
                state.getReasonDetailsPopUpLoading = false;
                state.getReasonDetailsPopUpData = [];
                state.error = true;
            })

            .addCase(NonCompliantAckTableList.pending, (state, action) => {
                state.NonCompliantAckTableListLoading = true;
                state.error = false;
            })
            .addCase(NonCompliantAckTableList.fulfilled, (state, action) => {
                state.NonComplaintAckTableData = action.payload;
                state.NonCompliantAckTableListLoading = false;
                state.error = false;
            })
            .addCase(NonCompliantAckTableList.rejected, (state, action) => {
                state.NonCompliantAckTableListLoading = false;
                state.NonComplaintAckTableData = [];
                state.error = true;
            })

            .addCase(NonCompliantAckTableListCount.pending, (state, action) => {
                state.NonCompliantAckTableCountDataLoading = true;
                state.error = false;
            })
            .addCase(NonCompliantAckTableListCount.fulfilled, (state, action) => {
                state.NonComplaintAckTableCountData = action.payload;
                state.NonCompliantAckTableCountDataLoading = false;
                state.error = false;
            })
            .addCase(NonCompliantAckTableListCount.rejected, (state, action) => {
                state.NonCompliantAckTableCountDataLoading = false;
                state.NonComplaintAckTableCountData = [];
                state.error = true;
            })


            // Info list API
            .addCase(getInfoDetailsPopUp.pending, (state, action) => {
                state.InfoListDataLoading = true;
                state.error = false;
            })
            .addCase(getInfoDetailsPopUp.fulfilled, (state, action) => {
                state.InfoListData = action.payload;
                state.InfoListDataLoading = false;
                state.error = false;
            })
            .addCase(getInfoDetailsPopUp.rejected, (state, action) => {
                state.InfoListDataLoading = false;
                state.InfoListData = [];
                state.error = true;
            })

            // Manage Compliance API
            .addCase(ManageCompliance.pending, (state, action) => {
                state.manageComplianceLoading = true;
                state.error = false;
            })
            .addCase(ManageCompliance.fulfilled, (state, action) => {
                state.manageComplianceData = action.payload;
                state.manageComplianceLoading = false;
                state.error = false;
            })
            .addCase(ManageCompliance.rejected, (state, action) => {
                state.manageComplianceLoading = false;
                state.manageComplianceData = [];
                state.error = true;
            })

            // getNonComplianceTeamAPI
            .addCase(getNonComplianceTeamAPI.pending, (state, action) => {
                state.getNonComplianceTeamLoading = true;
                state.error = false;
            })
            .addCase(getNonComplianceTeamAPI.fulfilled, (state, action) => {
                state.getNonComplianceTeamData = action.payload;
                state.getNonComplianceTeamLoading = false;
                state.error = false;
            })
            .addCase(getNonComplianceTeamAPI.rejected, (state, action) => {
                state.getNonComplianceTeamLoading = false;
                state.getNonComplianceTeamData = [];
                state.error = true;
            })

            // getNonComplianceActionPlanAPI
            .addCase(getNonComplianceActionPlanAPI.pending, (state, action) => {
                state.getNonComplianceActionPlanLoading = true;
                state.error = false;
            })
            .addCase(getNonComplianceActionPlanAPI.fulfilled, (state, action) => {
                state.getNonComplianceActionPlanAPIData = action.payload;
                state.getNonComplianceActionPlanLoading = false;
                state.error = false;
            })
            .addCase(getNonComplianceActionPlanAPI.rejected, (state, action) => {
                state.getNonComplianceActionPlanLoading = false;
                state.getNonComplianceActionPlanAPIData = [];
                state.error = true;
            })

            // getComplianceStatusCountAPI
            .addCase(getComplianceStatusCountAPI.pending, (state, action) => {
                state.getComplianceStatusCountLoading = true;
                state.error = false;
            })
            .addCase(getComplianceStatusCountAPI.fulfilled, (state, action) => {
                state.getComplianceStatusCountData = action.payload;
                state.getComplianceStatusCountLoading = false;
                state.error = false;
            })
            .addCase(getComplianceStatusCountAPI.rejected, (state, action) => {
                state.getComplianceStatusCountLoading = false;
                state.getComplianceStatusCountData = [];
                state.error = true;
            })

            // getNonCompFindingsDistributionAPI
            .addCase(getNonCompFindingsDistributionAPI.pending, (state, action) => {
                state.getNonCompFindingsDistributionLoading = true;
                state.error = false;
            })
            .addCase(getNonCompFindingsDistributionAPI.fulfilled, (state, action) => {
                state.getNonCompFindingsDistributionData = action.payload;
                state.getNonCompFindingsDistributionLoading = false;
                state.error = false;
            })
            .addCase(getNonCompFindingsDistributionAPI.rejected, (state, action) => {
                state.getNonCompFindingsDistributionLoading = false;
                state.getNonCompFindingsDistributionData = [];
                state.error = true;
            })

            // getNonCompACKFindingsDistributionAPI
            .addCase(getNonCompACKFindingsDistributionAPI.pending, (state, action) => {
                state.getNonCompACKFindingsDistributionLoading = true;
                state.error = false;
            })
            .addCase(getNonCompACKFindingsDistributionAPI.fulfilled, (state, action) => {
                state.getNonCompACKFindingsDistributionData = action.payload;
                state.getNonCompACKFindingsDistributionLoading = false;
                state.error = false;
            })
            .addCase(getNonCompACKFindingsDistributionAPI.rejected, (state, action) => {
                state.getNonCompACKFindingsDistributionLoading = false;
                state.getNonCompACKFindingsDistributionData = [];
                state.error = true;
            })

            // getFindingsTrendSirarAPI
            .addCase(getFindingsTrendSirarAPI.pending, (state, action) => {
                state.getFindingsTrendSirarLoading = true;
                state.error = false;
            })
            .addCase(getFindingsTrendSirarAPI.fulfilled, (state, action) => {
                state.getFindingsTrendSirarData = action.payload;
                state.getFindingsTrendSirarLoading = false;
                state.error = false;
            })
            .addCase(getFindingsTrendSirarAPI.rejected, (state, action) => {
                state.getFindingsTrendSirarLoading = false;
                state.getFindingsTrendSirarData = [];
                state.error = true;
            })

            // getNonCompliantByCRCategoriesAPI
            .addCase(getNonCompliantByCRCategoriesAPI.pending, (state, action) => {
                state.getNonCompliantByCRCategoriesLoading = true;
                state.error = false;
            })
            .addCase(getNonCompliantByCRCategoriesAPI.fulfilled, (state, action) => {
                state.getNonCompliantByCRCategoriesData = action.payload;
                state.getNonCompliantByCRCategoriesLoading = false;
                state.error = false;
            })
            .addCase(getNonCompliantByCRCategoriesAPI.rejected, (state, action) => {
                state.getNonCompliantByCRCategoriesLoading = false;
                state.getNonCompliantByCRCategoriesData = [];
                state.error = true;
            })

            // getNonCompliantByApplicationAPI
            .addCase(getNonCompliantByApplicationAPI.pending, (state, action) => {
                state.getNonCompliantByApplicationLoading = true;
                state.error = false;
            })
            .addCase(getNonCompliantByApplicationAPI.fulfilled, (state, action) => {
                state.getNonCompliantByApplicationData = action.payload;
                state.getNonCompliantByApplicationLoading = false;
                state.error = false;
            })
            .addCase(getNonCompliantByApplicationAPI.rejected, (state, action) => {
                state.getNonCompliantByApplicationLoading = false;
                state.getNonCompliantByApplicationData = [];
                state.error = true;
            })


            //getTeams
            .addCase(getAllTeams.pending, (state, action) => {
                state.TeamsLoading = true;
                state.error = false;
            })
            .addCase(getAllTeams.fulfilled, (state, action) => {
                state.TeamsData = action.payload;
                state.TeamsLoading = false;
                state.error = false;
            })
            .addCase(getAllTeams.rejected, (state, action) => {
                state.TeamsLoading = false;
                state.TeamsData = [];
                state.error = true;
            })

            //getRisk
            .addCase(getRisk.pending, (state, action) => {
                state.RiskLoading = true;
                state.error = false;
            })
            .addCase(getRisk.fulfilled, (state, action) => {
                state.RiskData = action.payload;
                state.RiskLoading = false;
                state.error = false;
            })
            .addCase(getRisk.rejected, (state, action) => {
                state.RiskLoading = false;
                state.RiskData = [];
                state.error = true;
            })
    }
});
export const {
    resetAllFilter,
    setSelectedFiltersData,
    setError,
    setActiveTabKey,
    setTableReload,
    resetSirarSliceData,
    setTableData
} = SirarfilterSlice.actions;
export default SirarfilterSlice.reducer;